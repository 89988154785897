import React from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ogIMage from "../../../../images/og.png";

const OurStoryImpactOne = () => {
    return (
        <Layout>
            <Seo
                title={"Shaping future for the children of Mannivakkam"}
                description="Witnessing the journey of shaping futures, we joined forces with Round Table India to create a conducive learning environment for young minds of Mannivakkam school. "
                image={ogIMage}
            />
            <section className="csr-our-stories">
                <div className="container container--first-v3">
                    <div className="csr-our-stories__text gap-2x">
                        <div className="text-p3 gap-2x csr-our-stories__text-breadcrumbs text-fw-normal">
                            <Link to={`/about/csr`} className="text-decor-none">
                                CSR
                            </Link>
                            /<Link className="text-decore none">Our Stories Of Impact</Link>
                        </div>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-2x">
                            Building Dreams, Shaping Futures: Sedin and Round Table India Unite for
                            Mannivakkam Primary School
                        </h1>
                        <div className="csr-our-stories__banner-img gap-3x">
                            <StaticImage
                                src="../../../../images/csrpage/our-stories-banner-img-1.png"
                                alt="banner-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="banner-img"
                            />
                        </div>
                        <h2 className="text-h2 text-clr-primary text-fw-medium gap-1x">
                            Our Compassion in Action
                        </h2>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Sedin Technologies is immensely proud to have played a pivotal role in a
                            transformative project, collaborating with Round Table India, to
                            construct a new Six Classroom Block and Four Toilet Block at Mannivakkam
                            Primary School. 
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            This project aims to provide a conducive learning environment for the
                            young minds of Mannivakkam, enabling them to explore, learn, and grow to
                            their fullest potential. 
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            With the shared vision of empowering education and enriching lives,
                            Sedin and Round Table India have come together to create a lasting
                            impact on the community.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            As Sedin stands tall alongside its commitment of creating a safe and
                            hygienic environment, we envision a future where every child's dream can
                            take flight, and every classroom becomes a doorway to endless
                            possibilities for the children of Mannivakkam and beyond.
                        </p>
                    </div>
                </div>
            </section>
            {/* our-stories-image-gallery section */}
            <section className="csr-our-stories-img-gallery">
                <div className="container container--last">
                    <div className="csr-our-stories-img-gallery__grid">
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-01-1.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-01-2.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-01-3.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-01-4.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-01-5.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OurStoryImpactOne;
